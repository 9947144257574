@font-face {
  font-family: "Hoop Bold";
  src: url("../assets/fonts/Typo\ Hoop_Bold\ Demo.otf");
}

@font-face {
  font-family: "Hoop Regular";
  src: url("../assets/fonts/Typo\ Hoop_Regular_Demo.otf");
}

@font-face {
  font-family: "Hoop Light";
  src: url("../assets/fonts/Typo Hoop_Light Demo.otf");
}

@font-face {
  font-family: "Hoop Hairline";
  src: url("../assets/fonts/Typo Hoop_Hairline Demo.otf");
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

p {
  font-family: "Montserrat Thin", sans-serif;
  font-weight: 100;
  line-height: 1.5;
}

h1,
h2 {
  font-family: "Hoop Bold", sans-serif;
  font-weight: bold;
}

body {
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
